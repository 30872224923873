import React, { useState } from "react";
import Modal from 'react-modal';
import './Kontakt.css';
import SidebarMenu from "./Components_2/sideBarMenu";
import CarouselWithMap from './Components_2/CarouselWithMap';
import {useTranslation} from "react-i18next";

const Modalcomplete = ({ isOpen, messagemodal, onRequestClose }) => {
    const aspectRatio = 300 / 150;

    const modalStyle = {
        content: {
            width: '80vw',
            maxWidth: '300px',
            height: `calc(80vw / ${aspectRatio})`,
            maxHeight: '150px',
            margin: 'auto',
            borderRadius: '25px',
            padding: '20px',
            textAlign: 'center',
        },
    };

    const buttonStyle = {
        backgroundColor: '#2CD845',
        borderRadius: '25px',
        padding: '10px 20px',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    };

    return (
        <div className="modal">
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Custom Modal"
            style={modalStyle}
        >
            <div>
                <p>{messagemodal}</p>
                <button style={buttonStyle} onClick={onRequestClose}>Zavřít</button>
            </div>
        </Modal>
        </div>
    );
};

function Kontakt() {
    const [t] = useTranslation("global");
    const [showModal, setShowModal] = useState(false);
    const [messagemodal, setMessagemodal] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (event) => 
    {
        event.preventDefault();

        const formData = 
        {
            firstName: event.target.firstName.value,
            lastName: event.target.lastName.value,
            email: event.target.email.value,
            text: event.target.message.value
        };

        try 
        {
            const response = await fetch(process.env.REACT_APP_API_URL+'/api/kpbi-kontakts', 
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: formData }),
            });

            if (!response.ok) 
            {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            setFirstName("");
            setLastName("");
            setEmail("");
            setMessage("");

            console.log('Data odeslána');
            setMessagemodal('Data byla úspěšně odeslaná');
            setShowModal(true);

        } catch (error) 
        {
            console.error('Error ', error);
        }
    };
    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{t("kontakt")}</h3>
                            <hr className="green-line"/>
                            <h6>Zanechte nám svoje kontakty, přes které nás můžete s vaším dotazem nebo připomínkou
                                kontaktovat.</h6>
                        </div>
                        <div className="col-lg-6 mx-auto ">
                            <form className="text-left" onSubmit={handleSubmit}>
                                <div className="form-group d-flex">
                                    <div className="d-flex flex-column mr-2 flex-grow">
                                        <label htmlFor="firstName">{t("jmeno")}<span
                                            className="required">*</span></label>
                                        <input type="text" id="firstName" name="firstName"
                                               value={firstName} onChange={e => setFirstName(e.target.value)}
                                               className="form-control input" required/>
                                    </div>
                                    <div className="d-flex flex-column ml-2 flex-grow">
                                        <label htmlFor="lastName">{t("prijmeni")}<span
                                            className="required">*</span></label>
                                        <input type="text" id="lastName" name="lastName"
                                               value={lastName} onChange={e => setLastName(e.target.value)}
                                               className="form-control input" required/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">E-mail: <span className="required">*</span></label>
                                    <input type="email" id="email" name="email"
                                           value={email} onChange={e => setEmail(e.target.value)}
                                           className="form-control input" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">{t("zprava")}</label>
                                    <textarea id="message" name="message"
                                              value={message} onChange={e => setMessage(e.target.value)}
                                              className="form-control" rows="4"></textarea>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary" style={{
                                        backgroundColor: '#2CD845',
                                        borderRadius: '25px',
                                        borderColor: '#2CD845',
                                        paddingLeft: '20px',
                                        paddingRight: '20px'
                                    }}>Odeslat
                                    </button>
                                </div>
                                {showModal && (
                                    <Modalcomplete
                                        isOpen={showModal}
                                        messagemodal={messagemodal}
                                        onRequestClose={() => setShowModal(false)}
                                    />
                                )}
                            </form>
                        </div>
                    </div>
                </div>

                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}

export default Kontakt;