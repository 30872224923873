import React from "react";
import './Arrow_up.css'

function Arrowup({ onClick, showArrow }) {
    return (
        <div className={showArrow ? "arrow-up-container" : "arrow-up-container hidden"} onClick={onClick}>
            <div className="arrow_back">
                <div className="close-icon">&#9650;</div>
            </div>
        </div>
    );
}

export default Arrowup;