import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import SidebarMenu from './Components_2/sideBarMenu';
import './Dokumenty.css';
import CarouselWithMap from "./Components_2/CarouselWithMap";
import {useTranslation} from "react-i18next";
import { useNavigate   } from 'react-router-dom';

function Dokumenty() {
    const [t, i18n] = useTranslation("global");
    const [dokumenty, setDokumenty] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, pageCount: 0, pageSize: 20, total: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Function to fetch data
    const fetchData = useCallback((pageNumber, pageSize, language) => {
        setLoading(true);
        console.log(process.env.REACT_APP_API_URL+`/api/kpbi-dokumenties?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&sort[0]=id:desc`);
        fetch(process.env.REACT_APP_API_URL+`/api/kpbi-dokumenties?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&locale=${language}&sort[0]=id:desc`) //
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setDokumenty(data.data);
                setPagination(data.meta.pagination);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);


    useEffect(() => {
        fetchData(pagination.page, pagination.pageSize, i18n.language);
    }, [pagination.page,pagination.pageSize,i18n.language, fetchData]);


    const handlePageChange = (newPage) => {
            setPagination(prevState => ({ ...prevState, page: newPage }));
    };

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;

    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className='row'>
                <div className='col-lg-9'>
                    <div className="container">
                        <div className="nadpis_padding">
                            <h3>{t("dokumenty_v")}</h3>
                            <hr className="green-line"/>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center container'>
                        <br></br>
                        <table className='table table-stripe table_view'>
                            <thead>
                            <tr>
                                <th>{t("nazev")}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {dokumenty.map(document => (
                                <tr key={document.id}>
                                    <td>{document.attributes.name}</td>
                                    <td>
                                        <Link to={`/dokument-detail/${document.id}`}
                                              className='btn btn-primary btn_vice'>{t("vice")}</Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            {pagination.pageCount > 1 && (
                                <>
                                    <br></br>
                                    <ul className='pagination '>
                                        {[...Array(pagination.pageCount)].map((_, index) => (
                                            <li
                                                key={index}
                                                className={`page-item  ${
                                                    pagination.page === index + 1 ? 'active' : ''
                                                }`}
                                            >
                                                <button
                                                    className='page-link'
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </table>
                    </div>
                    <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                        {t("zpet")}
                    </button>
                </div>
                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}

export default Dokumenty;
