import React, { useState, useEffect } from 'react';
import './Calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/cs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from "react-i18next";

function CustomToolbar({ label, onNavigate, onView }) {
    const [t] = useTranslation("global");

    const navigate = action => {
        onNavigate(action);
    };

    const view = view => {
        onView(view);
    };

    return (
        <div className="my-custom-toolbar">
            <div className="toolbar-row navigation-buttons">
                <button onClick={() => navigate('TODAY')}>{t('dnes')}</button>
                <button onClick={() => navigate('PREV')}>{t('predchozi')}</button>
                <button onClick={() => navigate('NEXT')}>{t('dalsi')}</button>
            </div>
            <div className="toolbar-row date-label">
                <span>{label}</span>
            </div>
            <div className="toolbar-row view-buttons">
                <button onClick={() => view('month')}>{t('mesic')}</button>
                <button onClick={() => view('week')}>{t('tyden')}</button>
                <button onClick={() => view('day')}>{t('den')}</button>
            </div>
            <div className="toolbar-row view-buttons">
                <button onClick={() => view('agenda')}>{t('agenda')}</button>
            </div>
        </div>
    );
}


function MyCalendar() {
    const [t, i18n] = useTranslation("global");
    moment.locale(i18n.language);
    const localizer = momentLocalizer(moment);

    
    const [udalosti, setUdalosti] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Získání dat
    
    useEffect(() => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL+`/api/kpbi-udalosti-do-kalendares`) //
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const events = data.data.map(udalost => ({
                    title: udalost.attributes.name,
                    start: new Date(udalost.attributes.start),
                    end: new Date(udalost.attributes.end),
                }));
                setUdalosti(events);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);


    const messages = {
        allDay: t('celyDen'),
        previous: t('zpet'),
        next: t('dalsi'),
        today: t('dnes'),
        month: t('mesic'),
        week: t('tyden'),
        day: t('den'),
        agenda: t('agenda'),
        date: t('datum'),
        time: t('cas'),
        event: t('udalost'),
        noEventsInRange: t('agendaText')
    };


    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;
    

    return (
        <div style={{ height: 700 }}>
            <Calendar
                components={{
                    toolbar: CustomToolbar
                }}
                localizer={localizer}
                events={udalosti}
                startAccessor="start"
                endAccessor="end"
                messages={messages}
                style={{ height: 500 }}
            />
        </div>
    );
}

export default MyCalendar;
