import { Carousel } from 'react-bootstrap';
import uvod_1 from '../pics/uvod_1.jpg';
import uvod_2 from '../pics/uvod_2.jpg';

function CarouselWithMap() {
    return (
        <Carousel controls={false} indicators={false}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={uvod_1}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={uvod_2}
                    alt="Second slide"
                />
            </Carousel.Item>
        </Carousel>
    );
}

export default CarouselWithMap;