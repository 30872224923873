import React, { useState, useEffect, useCallback } from 'react';
import common from "./pics/logo_top.png";
import CarouselWithMap from './Components_2/CarouselWithMap';
import SidebarMenu from "./Components_2/sideBarMenu";
import './Aktualne_detail.css';
import { useNavigate , Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

function Aktualne() {
    const [t, i18n] = useTranslation("global");
    const [aktualne, setAktualne] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, pageCount: 0, pageSize: 20, total: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Function to fetch data
    const fetchData = useCallback((pageNumber, pageSize, language) => {
        setLoading(true);
        console.log(process.env.REACT_APP_API_URL + `/api/kpbi-aktualities?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&locale=${language}&sort[0]=id:desc&populate=*`);
        fetch(process.env.REACT_APP_API_URL + `/api/kpbi-aktualities?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&locale=${language}&sort[0]=id:desc&populate=*`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAktualne(data.data);
                setPagination(data.meta.pagination);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(pagination.page, pagination.pageSize, i18n.language);
    }, [pagination.page, pagination.pageSize, i18n.language, fetchData]);

    // Handle page change
    const handlePageChange = (newPage) => {
        setPagination(prevState => ({ ...prevState, page: newPage }));
    };

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;


    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className='row'>
                <div className='col-lg-9'>
                    <div className="col-lg-12">
                        <div className="nadpis_padding">
                            <h3>{t("seminare_aktualne")}</h3>
                            <hr className="green-line"/>
                        </div>
                        <div className='row'>
                            {aktualne.map(aktualni => (
                                <div key={aktualni.id} className="col-md-6 mb-4">
                                    <Link to={`/aktualne-detail/${aktualni.id}`} className="odkaz_projekt">
                                        <div className="card h-100">
                                            <img className="card-img-top"
                                                 src={aktualni.attributes.coverImage.data ? process.env.REACT_APP_API_URL + aktualni.attributes.coverImage.data[0].attributes.url : common}
                                                 alt={aktualni.attributes.name}/>
                                            <div className="card-body">
                                                <p className="card-title projekt_odkaz">{aktualni.attributes.name}</p>
                                                <p className="vice">{t("vice")}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        {pagination.pageCount > 1 && (
                            <div>
                                <ul className='pagination'>
                                    {[...Array(pagination.pageCount)].map((_, index) => (
                                        <li
                                            key={index}
                                            className={`page-item  ${pagination.page === index + 1 ? 'active' : ''}`}
                                        >
                                            <button
                                                className='page-link'
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                        {t("zpet")}
                    </button>
                </div>
                <SidebarMenu/>
            </div>
        </div>
    );
}

export default Aktualne;