import React, { useState, useEffect, useCallback } from 'react';
import './Aktuality_z_kraju.css';
import common from "./pics/logo_top.png";
import CarouselWithMap from './Components_2/CarouselWithMap';
import SidebarMenu from "./Components_2/sideBarMenu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Aktuality_z_kraju() {
    const [t, i18n] = useTranslation("global");

    const [aktuality, setAktuality] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, pageCount: 0, pageSize: 20, total: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = useCallback((pageNumber, pageSize, language) => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL + `/api/kpbi-aktuality-krajus?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&sort[0]=id:desc&locale=${language}&populate=*`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAktuality(data.data);
                setPagination(data.meta.pagination);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(pagination.page, pagination.pageSize, i18n.language);
    }, [pagination.page, pagination.pageSize, i18n.language, fetchData]);

    const handlePageChange = (newPage) => {
        setPagination(prevState => ({ ...prevState, page: newPage }));
    };

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;

    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap />
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{t("aktuality_z_kraju")}</h3>
                            <hr className="green-line" />
                        </div>
                        {aktuality.map(aktualita => (
                            <div key={aktualita.id} className="col-md-6 mb-4">
                                <Link to={`/aktuality-z-kraju-detail/${aktualita.id}`} className="odkaz_projekt">
                                    <div className="card h-100">
                                        <img
                                            className="card-img-top"
                                            src={aktualita.attributes.coverImage.data ? process.env.REACT_APP_API_URL + aktualita.attributes.coverImage.data[0].attributes.url : common}
                                            alt={aktualita.attributes.name}
                                        />
                                        <hr className="card_hr" />
                                        <div className="card-body">
                                            <p className="card-title projekt_odkaz">{aktualita.attributes.name}</p>
                                            <p className="vice">{t("vice")}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                        {pagination.pageCount > 1 && (
                            <>
                                <ul className='pagination'>
                                    {[...Array(pagination.pageCount)].map((_, index) => (
                                        <li
                                            key={index}
                                            className={`page-item  ${pagination.page === index + 1 ? 'active' : ''}`}
                                        >
                                            <button
                                                className='page-link'
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                </div>
                <SidebarMenu />
            </div>
        </div>
    );
}

export default Aktuality_z_kraju;
