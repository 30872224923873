import './Obsah.css';
import React, { useState, useEffect } from 'react';
import ReactMarkdown from "react-markdown";
import SidebarMenu from './Components_2/sideBarMenu';
import CarouselWithMap from "./Components_2/CarouselWithMap";
import {useTranslation} from "react-i18next";
import { useNavigate   } from 'react-router-dom';

function KontaktyKraj() {
    const [ t, i18n ] = useTranslation("global");
    const navigate = useNavigate();

    const [kontaky, setKontakty] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+`/api/kpbi-kontaky-na-krajes?locale=${i18n.language}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setKontakty(data.data);
                setLoading(false);
                console.log(data.data);
                
            })
            .catch(error => {
                setError(error);
                console.log(error);
                setLoading(false);
            });
    }, [i18n.language]);


    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba...</div>;


    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className='row'>
                <div className="col-lg-9">
                    {
                        kontaky.map((kontakt) =>
                            (
                                <div className="nadpis_padding">
                                    <h3>{kontakt.attributes.name}</h3>
                                    <hr className="green-line"/>
                                    <div>
                                        <ReactMarkdown key={kontakt.id}>
                                            {kontakt.attributes.description}
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            ))
                    }
                    <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                        {t("zpet")}
                    </button>
                </div>
                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}
export default KontaktyKraj;