import React from "react";
import CarouselWithMap from "./Components_2/CarouselWithMap";
import SidebarMenu from "./Components_2/sideBarMenu";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import './GDPR.css';

function GDPR() {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{t("gdpr_nadpis")}</h3>
                            <hr className="green-line"/>
                        </div>
                        {i18n.language === 'cs' ? (
                            <div>
                                <p>Děkujeme za Váš zájem o odběr newsletteru projektu Kraje pro bezpečný internet na
                                    webových stránkách <Link to="/"> www.kpbi.cz</Link>.</p>

                                <p>Pro úspěšné dokončení registrace k odběru newsletteru je nezbytný souhlas s pravidly
                                    provozu
                                    stránek, tedy se zpracováním osobních údajů, v rozsahu Vaší e-mailové adresy. Tyto
                                    údaje budou
                                    zpracovány po dobu, po kterou bude Správce provozovat stránky <Link
                                        to="/"> www.kpbi.cz</Link> a
                                    vydávat newsletter.</p>

                                <p>Tyto údaje budou zpracovány za účelem pravidelného rozeslání newsletteru s
                                    informacemi o
                                    novinkách a aktivitách projektu Kraje pro bezpečný internet.</p>

                                <p>Správcem osobních údajů je Kraj Vysočina, Žižkova 57, 586 01, Jihlava. Kontaktní
                                    osobou je
                                    koordinátorka projektu KPBI Lucie Časarová, tel.: 721 947 046, <a
                                        href="mailto:casarova.l@kr-vysocina.cz">casarova.l@kr-vysocina.cz</a>.</p>

                                <p className="odrazky">Vezměte, prosíme, na vědomí, že máte právo:
                                    vzít souhlas kdykoliv zpět zasláním e-mailu na výše uvedený kontakt,<br/>
                                    požadovat po nás informaci, jaké vaše osobní údaje zpracováváme,<br/>
                                    požadovat po nás vysvětlení ohledně zpracování osobních údajů,<br/>
                                    vyžádat si u nás přístup k těmto údajům a tyto nechat aktualizovat nebo
                                    opravit,<br/>
                                    požadovat po nás výmaz těchto osobních údajů,<br/>
                                    v případě pochybností o dodržování povinností souvisejících se zpracováním osobních
                                    údajů
                                    obrátit se na nás nebo na Úřad pro ochranu osobních údajů.</p>


                                <p>V případě dotazů či požadavků, nás prosím kontaktujte na email <a
                                    href="mailto:podpora@kpbi.cz">podpora@kpbi.cz</a>.</p>
                                <div className="details-container">
                                    <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                                        {t("zpet")}
                                    </button>
                                </div>
                            </div>

                        ) : (
                            <div>
                                <p>Thank you for your interest in subscribing to the newsletter of the project Countries
                                    for Safe Internet at website <Link to="/"> www.kpbi.cz</Link>.</p>

                                <p>In order to successfully complete the registration for the newsletter, it is
                                    necessary to agree to the rules of operation of the website, i.e. to the processing
                                    of personal data to the extent of your e-mail address. This data will be processed
                                    for as long as the Administrator operates the site
                                    <Link to="/"> www.kpbi.cz</Link> a publish the newsletter.</p>

                                <p>This data will be processed for the purpose of sending a regular newsletter with
                                    information about news and activities of the Regions for a Safe Internet
                                    project.</p>

                                <p>The administrator of personal data is the Vysočina Region, Žižkova 57, 586 01,
                                    Jihlava. Contact person is Lucie Časarová,
                                    KPBI project coordinator, tel.: 721 947 046, <a
                                        href="mailto:casarova.l@kr-vysocina.cz">casarova.l@kr-vysocina.cz</a>.</p>

                                <p className="odrazky">Please note that you have the right to:

                                    withdraw your consent at any time by sending an email to the above contact,<br/>
                                    request information from us about what personal data we process about you,<br/>
                                    request an explanation from us regarding the processing of personal data,<br/>
                                    request access to this data from us and have it updated or corrected,<br/>
                                    require us to delete this personal data, <br/>
                                    contact us or the Office for Personal Data Protection in case of any doubts about
                                    compliance with the obligations related to the processing of personal data.</p>


                                <p>In case of questions or requests, please contact us by email <a
                                    href="mailto:podpora@kpbi.cz">podpora@kpbi.cz</a>.</p>
                                <div className="details-container">
                                    <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                                        {t("zpet")}
                                    </button>
                                </div>
                            </div>

                        )}


                    </div>
                </div>
                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}

export default GDPR;