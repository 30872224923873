import React from "react";
import './Oprojektu.css';
import info from "./pics/info.svg";
import dokumenty from "./pics/document.svg";
import kontakty from "./pics/kontakt.svg";
import partneri from "./pics/partneri.svg";
import konference from "./pics/konference.svg";
import CarouselWithMap from './Components_2/CarouselWithMap';
import SidebarMenu from "./Components_2/sideBarMenu";
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

function Oprojektu() {
    const [t] = useTranslation("global");

    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{t("o_projektu")}</h3>
                            <hr className="green-line"/>
                        </div>
                        <div className="col-md-6 mb-4">
                            <Link to="/informace-o-projektu" className="odkaz_projekt">
                                <div className="image-container">
                                    <img src={info}
                                         alt="dite" className="picture_vim_kam_klikam"/>
                                    <div className="menu_klikam">
                                        <p className="card-title projekt_odkaz">{t("informace_o_projektu")}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 mb-4">
                            <Link to="/partneri-projektu" className="odkaz_projekt">
                                <div className="image-container">
                                    <img src={partneri}
                                         alt="dite" className="picture_vim_kam_klikam"/>
                                    <div className="menu_klikam">
                                        <p className="card-title projekt_odkaz">{t("partneri_projektu_m")}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 mb-4">
                            <Link to="/kontakty-na-kraje" className="odkaz_projekt">
                                <div className="image-container">
                                    <img src={kontakty}
                                         alt="dite" className="picture_vim_kam_klikam"/>
                                    <div className="menu_klikam">
                                        <p className="card-title projekt_odkaz">{t("kontakty_na_kraje")}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 mb-4">
                            <Link to="/seminare-konference" className="odkaz_projekt">
                                <div className="image-container">
                                    <img src={konference}
                                         alt="dite" className="picture_vim_kam_klikam"/>
                                    <div className="menu_klikam">
                                        <p className="card-title projekt_odkaz">{t("seminare_konference")}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 mb-4">
                            <Link to="/dokumenty" className="odkaz_projekt">
                                <div className="image-container">
                                    <img src={dokumenty}
                                         alt="dokuenty" className="picture_vim_kam_klikam"/>
                                    <div className="menu_klikam">
                                        <p className="card-title projekt_odkaz">{t("dokumenty")}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}

export default Oprojektu;