import Calendar from './Calendar';
import React, { useState, useEffect } from 'react';
import './sideBarMenu.css';
import {useTranslation} from "react-i18next";

function SidebarMenu() {
    const [t] = useTranslation("global");
    const [partneri, setPartneri] = useState([]);
    const [zastita, setZastita] = useState([]);

    const [loadingPartneri, setLoadingPartneri] = useState(true);
    const [loadingZastita, setLoadingZastita] = useState(true);

    const [errorPartneri, setErrorPartneri] = useState(null);
    const [errorZastita, setErrorZastita] = useState(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/api/kpbi-partneri-projektus?populate=*')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setPartneri(data.data);
                setLoadingPartneri(false);
                //console.log(data.data);
            })
            .catch(error => {
                setErrorPartneri(error);
                setLoadingPartneri(false);
            });
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/api/kpbi-zastita-nad-projektems?populate=*')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setZastita(data.data);
                setLoadingZastita(false);
                //console.log(data.data);
            })
            .catch(error => {
                setErrorZastita(error);
                setLoadingZastita(false);
            });
    }, []);

    if (loadingPartneri || loadingZastita) return <div>Načítání...</div>;
    if (errorPartneri || errorZastita) return <div>Chyba...</div>;
    return (

            <div className="col-lg-3">
                <br></br>
                <div>
                    <h4>{t("partneri_projektu")}</h4>
                    <hr className="green-line"/>
                    {
                            partneri.map((partner) => (
                            <div key={partner.id}>
                            {partner && partner.attributes.logo && partner.attributes.logo.data &&
                                <img
                                    src={`${process.env.REACT_APP_API_URL}${partner.attributes.logo.data.attributes.url}`}
                                    alt={partner.attributes.name}
                                    className={` ${partner.id === 6 || partner.id === 4 ? 'small-image' : 'img_side'}`}
                                />
                            }
                            <br></br>
                            </div>
                        ))
                        }

                </div>
                <div>
                    <h4>{t("zastita_nad_projektem")}</h4>
                    <hr className="green-line"/>
                    {
                            zastita.map((zastit) => (
                            <div>
                            {zastit && zastit.attributes.logo && zastit.attributes.logo.data &&
                                <img
                                    src={`${process.env.REACT_APP_API_URL}${zastit.attributes.logo.data.attributes.url}`}
                                    alt={zastit.attributes.name}
                                    className={` ${zastit.id === 1 ? 'small-image' : 'img_side'}`}
                                />
                            }
                            <br></br>
                            </div>
                        ))
                    }
                </div>
                <div className="fb">
                    <div className="fb-page fb_iframe_widget" data-href="https://www.facebook.com/kpbicz" data-width="280" data-height="500" data-hide-cover="false" data-show-facepile="true"
                         data-show-posts="true" >
                    <span className="fb_1"><iframe name="f940e95f9cc94" width="280px" height="500px" data-testid="fb:page Facebook Social Plugin" title="fb:page Facebook Social Plugin"
                                                    allowTransparency="true" allowFullScreen="true"  allow="encrypted-media"
                                                   src="https://www.facebook.com/v2.3/plugins/page.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df7452084e987c%26domain%3Dwww.kpbi.cz%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.kpbi.cz%252Ffa1cbfe27e0f44%26relation%3Dparent.parent&amp;container_width=270&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fkpbicz&amp;locale=cs_CZ&amp;sdk=joey&amp;show_facepile=true&amp;show_posts=true&amp;width=280" className="fb_2">
                    </iframe></span></div>
                </div>
                <hr className="green-line"/>
                <div className="ig">
                    <iframe width="280" height="500" src="https://www.instagram.com/p/CRx_veQD1Iv/embed" title="ig_frame"></iframe>
                </div>
                <Calendar />
            </div>
    );
}

export default SidebarMenu;