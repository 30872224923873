import React, { useState } from 'react';
import Modal from 'react-modal';
import './Newsletter.css';
import KpbiLogo from './pics/logo_top.png';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function Newsletter({ onClose }) {
    const [t] = useTranslation("global");
    const [isClosed, setClosed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messagemodal, setMessagemodal] = useState("");
    const [email, setEmail] = useState("");

    const handleClose = () => {
        setClosed(true);
        onClose();
    };

    if (isClosed) {
        return null;
    }

    
    const Modalcomplete = ({ isOpen, messagemodal, onRequestClose }) => {
    const aspectRatio = 300 / 150;

    const modalStyle = {
        content: {
            width: '80vw',
            maxWidth: '300px',
            height: `calc(80vw / ${aspectRatio})`,
            maxHeight: '150px',
            margin: 'auto',
            borderRadius: '25px',
            padding: '20px',
            textAlign: 'center',
        },
    };

    const buttonStyle = {
        backgroundColor: '#2CD845',
        borderRadius: '25px',
        padding: '10px 20px',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    };

    return (
        <div className="modal">
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Custom Modal"
            style={modalStyle}
        >
            <div>
                <p>{messagemodal}</p>
                <button style={buttonStyle} onClick={onRequestClose}>Zavřít</button>
            </div>
        </Modal>
        </div>
        );
    };

    const handleSubmitEmail = async (event) => 
    {
        event.preventDefault();

        const emailData = 
        {
            email: event.target.email.value,
        };

        try 
        {
            const response = await fetch(process.env.REACT_APP_API_URL+'/api/kpbi-newsletters', 
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: emailData }),
            });

            if (!response.ok) 
            {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            setEmail("");
            console.log('Data odeslána');
            setMessagemodal('Data byla úspěšně odeslaná');
            setShowModal(true);
        } catch (error) 
        {
            console.error('Error ', error);
        }
    };
    return (
        <div>
            <div className="close-container" onClick={handleClose}>
                <div className="arrow_back">
                <div className="close-icon">▼</div>
                </div>
            </div>
            <div className="footer">
                <div className="container">
                    <div className="newsletter-logo">
                        <img src={KpbiLogo} alt="KPBIlogo"  className="img-fluid newsletter" />
                    </div>
                    <div className="newsletter-text">
                        <h1>NEWSLETTER</h1>
                        <p className="newsletter-paragraph">{t("zadej_email")}</p>
                        <form className="newsletter-input" onSubmit={handleSubmitEmail}>
                            <label htmlFor="email" className="input_newsletter">E-mail</label>
                            <input type="text" id="email" name="email" className="input_email" value={email} onChange={e => setEmail(e.target.value)} required/>
                            <button type="submit" className="btn btn_odebirat">{t("odebirat")}</button>
                        </form>
                        <p style={{ fontSize: '0.8em', paddingTop: '10px'  }}>{t("odesilani")} <Link
                            to={`/gdpr`}
                            style={{color: 'white'}}>{t("podminky_zpracovani")}</Link></p>
                    </div>
                    {showModal && (
                                <Modalcomplete
                                    isOpen={showModal}
                                    messagemodal={messagemodal}
                                    onRequestClose={() => setShowModal(false)}
                                />
                            )}
                </div>
            </div>
            <hr className="dark-line"/>
        </div>
    );
}

export default Newsletter;