import React from "react";
import CarouselWithMap from "./Components_2/CarouselWithMap";
import { useNavigate} from "react-router-dom";
import SidebarMenu from "./Components_2/sideBarMenu";
import {useTranslation} from "react-i18next";


function Skoleni() {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{t("skoleni")}</h3>
                            <hr className="green-line"/>
                            <br/>
                            <p>{t("hlaska_seminar")} <u>podpora@kpbi.cz. </u></p>
                        </div>
                        <div className="details-container">
                            <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                                {t("zpet")}
                            </button>
                        </div>
                    </div>
                </div>
                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}

export default Skoleni;