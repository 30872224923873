import React, { useState } from 'react';
import lupa from '../pics/lupa.svg';
import krizek from '../pics/kriz.svg';
import './Search_bar.css';

const SearchBar = ({ onSubmit }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
    };

    const handleClearClick = () => {
        setInputValue('');
        onSubmit('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(inputValue);
    };
    return (
        <div className="search-bar">
            <form onSubmit={handleSubmit}>
                <button type="submit" className="hledat_tlačitko">
                    <img src={lupa} alt="Search"/>
                </button>
                <input
                    type="text"
                    placeholder="Hledat..."
                    value={inputValue}
                    onChange={handleInputChange}
                    className="search"
                />
                    <button type="button" className="clear-icon" onClick={handleClearClick}>
                        <img src={krizek} alt="Clear"/>
                    </button>
            </form>
        </div>
    );
};

export default SearchBar;