import React,{ useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import './Hledatepomoc.css';
import uvod_1 from "./pics/uvod_1.jpg";
import SidebarMenu from "./Components_2/sideBarMenu";
import CarouselWithMap from './Components_2/CarouselWithMap';
import {useTranslation} from "react-i18next";

const UseFetchData = (url) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Network response was not ok, status: ${response.status}`);
                }
                const jsonData = await response.json();
                setData(jsonData.data);
                console.log(jsonData.data);
                setLoading(false);
            } catch (error) {
                setError(error);
                console.log(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    return { data, loading, error };
};

function Hledatepomoc() {

    const {i18n} = useTranslation("global");

    const { data: hledatePomoc, loading, error } = UseFetchData(process.env.REACT_APP_API_URL+`/api/kpbi-hledate-pomocs?locale=${i18n.language}&populate=*`);

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;
    if (!hledatePomoc || hledatePomoc.length === 0) return <div>Žádná data k zobrazení</div>;

    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{hledatePomoc[0].attributes.name}</h3>
                            <hr className="green-line"/>
                            <div className="podnadpis">
                                {hledatePomoc[0].attributes.description}
                            </div>
                        </div>
                        {
                            hledatePomoc.slice(1).map((pomoc) => (
                                <div className="col-md-6 mb-4">
                                    <div className="card h-100">
                                        <img
                                            src={(pomoc.attributes.images.data && pomoc.attributes.images.data.length > 0) ?
                                                `${process.env.REACT_APP_API_URL}${pomoc.attributes.images.data[0].attributes.url}` : uvod_1}
                                            alt={pomoc.attributes.name}
                                            className="img-fluid"
                                        />
                                        <hr className="card_hr"/>
                                        <div className="card-body">
                                            <ReactMarkdown key={pomoc.id}>
                                                {pomoc.attributes.description}
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <SidebarMenu></SidebarMenu>
            </div>
        </div>
    );
}

export default Hledatepomoc;