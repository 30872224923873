import React from "react";
import './Film_pas.css'

function Film_pas() {
    return (
        <div className='pas'>
            <div className='videos'>
                <div className="video-container">
                    <iframe
                        width="170"
                        height="95"
                        src="https://www.youtube.com/embed/videoseries?list=PLrT6AA3DsRDBr-z1pct75ojuBH97pAafd"
                        title="YouTube video player"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="video-container">
                    <iframe
                        width="170"
                        height="95"
                        src="https://www.youtube.com/embed/videoseries?list=PLrT6AA3DsRDB-H4TsmqVwMvXoR1U5AhmZ"
                        title="YouTube video player"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="video-container">
                    <iframe
                        width="170"
                        height="95"
                        src="https://www.youtube.com/embed/videoseries?list=PLrT6AA3DsRDBTsl0ATY0FkB6bM9rI9g_z"
                        title="YouTube video player"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="video-container">
                    <iframe
                        width="170"
                        height="95"
                        src="https://www.youtube.com/embed/videoseries?list=PLrT6AA3DsRDAot965CaRYx9U8YKtpECnr"
                        title="YouTube video player"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="video-container">
                    <iframe
                        width="170"
                        height="95"
                        src="https://www.youtube.com/embed/videoseries?list=PLrT6AA3DsRDBpWnRzaAezUy3D_IBh5HBD"
                        title="YouTube video player"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default Film_pas;